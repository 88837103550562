.ms_types{
    background-color: #F2F5FA;
}
.ms_types .container{
    max-width: 880px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 97px 0 126px;
}
.ms_types_title{
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #2E54EA;
}
.ms_types_question{
    font-weight: 900;
    font-size: 40px;
    line-height: 47px;
    color: #4A4A4A;
    margin: 0;
    margin-top: 30px;
}
.ms_types_desc{
    width: 580px;
    height: 62px;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    color: #4A4A4A;
    margin: 0;
    margin-top: 12px;
    margin-bottom: 24px;
}
.ms_types_list{
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.ms_types_list li{
    width: 202px;
    height: 234px;
    background: #FFFFFF;
    border-radius: 12px;
    margin-top: 24px;
    display: flex;
    justify-content: center;
}
.ms_types_list li a{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    padding: 40px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}
.ms_types_list li a .img_wrapper{
    height: 80px;
    overflow: hidden;
}
.ms_types_list li a img{
    background-color: white;
    width: 100px;
    height: auto;
}
.ms_types_list li a p{
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: #4A4A4A;
}
.ms_types .test_btn{
    width: 50%;
    margin: auto;
    margin-top: 66px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    text-decoration: none;
    background-color: #2E54EA;
    padding: 17px;
    border-radius: 4px;
}

@media (max-width: 768px) {
    .ms_types .container{
        max-width: 880px;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 36px 24px;
    }
    .ms_types_title{
        font-weight: 700;
        font-size: 12px;
        line-height: 19px;
        color: #2E54EA;
    }
    .ms_types_question{
        font-weight: 900;
        font-size: 22px;
        line-height: 140%;
        color: #4A4A4A;
        margin: 0;
        margin-top: 20px;
    }
    .ms_types_desc{
        width: fit-content;
        height: 62px;
        font-weight: 400;
        font-size: 15px;
        line-height: 140%;
        color: #4A4A4A;
        margin: 0;
        margin-top: 12px;
        margin-bottom: 24px;
    }
    .ms_types_list{
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .ms_types_list li{
        width: 48%;
        height: auto;
        background: #FFFFFF;
        border-radius: 12px;
        margin-top: 16px;
        display: flex;
        justify-content: center;
    }
    .ms_types_list li a{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;
        padding: 24px 12px;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }
    .ms_types_list li a .img_wrapper{
        height: 80px;
        overflow: hidden;
        margin-bottom: 8px;
    }
    .ms_types_list li a img{
        width: 80px;
        height: auto;
        margin-bottom: 12px;
    }
    .ms_types_list li a p{
        font-weight: 700;
        font-size: 13px;
        line-height: 140%;
        text-align: center;
        color: #4A4A4A;
    }
    .ms_types .test_btn{
        width: auto;
        min-width: 50%;
        margin: auto;
        margin-top: 22px;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #FFFFFF;
        text-decoration: none;
        background-color: #2E54EA;
        padding: 17px;
        border-radius: 4px;
    }

}