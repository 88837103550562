.ms_footer{
    background: #1F1E37;
    padding: 72px;
}
.ms_footer .container{
    max-width: 880px;
    margin: auto;
    display: flex;
    flex-direction: column;
}
.ms_footer_top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
}
.ms_footer_top_logo {
    font-family: 'Red Hat Display';
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: #FFFFFF;
}
.ms_footer_top ul{
    display: flex;
    flex-direction: row;
    justify-content: end;
    list-style: none;
}
.ms_footer_top ul li{
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: right;
    color: #FFFFFF;
}
.ms_footer_top ul li::after{
    content: "|";
    color: #E4E5E6;
    margin: 12px;
}
.ms_footer_top ul li:last-child::after{
    content: "";
    margin: 0;
}
.ms_footer_bottom::before{
    content: "";
    display: flex;
    border: 1px solid #3D4449;
    margin: 40px 0;
}
.ms_footer_bottom{
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #E4E5E6;
    display: flex;
    flex-direction: column;
}
.ms_footer_bottom_author{
    display: flex;
    flex-direction: row;
    justify-content: start;
}
.ms_footer_bottom_author p span{
    font-weight: 800;
    margin-left: 2px;
}
.ms_footer_bottom_author p:first-child::after{
    content: "|";
    color: #E4E5E6;
    margin: 12px;
}
.ms_footer_bottom_copyright{
    margin-top: 18px;
    color: #A3A3A3;
}


@media (max-width: 768px) {
    .ms_footer{
        padding: 24px;
    }
    .ms_footer .container{
        max-width: 880px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .ms_footer_top{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        font-family: 'Red Hat Display';
        font-weight: 700;
        font-size: 18px;
        line-height: 140%;
        color: #FFFFFF;
    }
    .ms_footer_top ul{
        display: flex;
        margin-top: 12px;
        flex-direction: column;
        justify-content: center;
    }
    .ms_footer_top ul li{
        font-weight: 400;
        font-size: 13px;
        line-height: 140%;
        text-align: left;
        color: #FFFFFF;
        margin-top: 4px;
    }
    .ms_footer_top ul li::after{
        content: "";
        margin: 0;
    }
    .ms_footer_top ul li:last-child::after{
        content: "";
        margin: 0;
    }
    .ms_footer_bottom::before{
        content: "";
        display: flex;
        border: 1px solid #3D4449;
        margin: 16px 0;
    }
    .ms_footer_bottom{
        font-weight: 400;
        font-size: 13px;
        color: #E4E5E6;
        display: flex;
        flex-direction: column;
        line-height: 140%;
    }
    .ms_footer_bottom_author{
        display: flex;
        flex-direction: column;
        justify-content: start;
        margin-bottom: 12px;
    }
    .ms_footer_bottom_author p span{
        margin-left: 0px;
    }
    .ms_footer_bottom_author p:first-child::after{
        content: "";
    }
    .ms_footer_bottom_copyright{
        margin-top: 12px;
        line-height: 140%;
    }
  }