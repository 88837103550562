.sd-root-modern{
    background: #F2F5FA;
    max-width: 880px;
    margin: auto;
    padding-bottom: 97px;
}
.sd-container-modern{
    margin: 0;
}
@media (max-width: 768px) {
    .sd-root-modern{
        margin-top: 60px;
        padding-bottom: 36px;
    }
    .sd-container-modern{
        margin: 0;
    }
}