.loading_table{
    width:100%;
    height:500px;
    background-color:#f2f5fa;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    border-radius:12px;
}
.margin_bottom_16px{
    margin-bottom: 16px;
}

@media (max-width: 768px) {
    .ms_chart_container{
        padding: 0 24px;
    }
    .loading_table{
        font-size: 13px;
    }
}